import { QuoteSlideInfo } from "components/molecules/QuoteSlideInfo";
import { mobile, tablet } from "components/templates/Breakpoints";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "../../styles";
import { QuoteSlide } from "../molecules/QuoteSlide";
import { QuoteSlideInfoProps } from "../molecules/QuoteSlideInfo";
interface QuoteCarouselProps {
  slides: QuoteSlideInfoProps[];
}

interface PreloadedImage {
  img?: HTMLImageElement;
  logo?: HTMLImageElement;
}

export const QuoteCarousel: FC<QuoteCarouselProps> = ({ slides }) => {
  const [slide, setSlide] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [translateX, setTranslateX] = useState("0");
  const [transitionSpeed, setTransitionSpeed] = useState("400ms");
  const [images, setImages] = useState<PreloadedImage[]>([]);

  useEffect(() => {
    // force the browser to preload all images
    const preloadedImages = slides.map((slide) => {
      const img = new Image();
      const logo = new Image();

      if (slide.bigImg) {
        img.src = slide.bigImg;
      }

      if (slide.logo) {
        logo.src = slide.logo;
      }

      return { img, logo };
    });

    setImages(preloadedImages);
  }, [slides]);

  const handleClick = (newSlide: number) => {
    setTransitionSpeed("400ms");
    setOpacity(0);
    setTranslateX("-20%");

    setTimeout(() => {
      setTransitionSpeed("250ms");
      setTranslateX("20%");
    }, 400);

    setTimeout(() => {
      setTranslateX("0");
      setOpacity(1);
      setSlide(newSlide);
    }, 650);
  };

  return (
    <>
      <Wrapper>
        <QuoteWrap $bigImg={slides[slide]?.bigImg}>
          <Transition
            $opacity={opacity}
            $translateX={translateX}
            $transitionSpeed={transitionSpeed}
          >
            <QuoteSlide
              logo={images[slide]?.logo?.src}
              bigImg={images[slide]?.img?.src}
              quote={slides[slide]?.quote}
              source={slides[slide]?.source}
            />
          </Transition>
        </QuoteWrap>
        <InfoSection $bigImg={slides[slide]?.bigImg}>
          <QuoteSlideInfo
            {...slides[slide]}
            opacity={opacity}
            translateX={translateX}
            transitionSpeed={transitionSpeed}
          />
          <CircleWrapper>
            {slides.length > 1 &&
              slides.map((carouselSlide, i) => {
                return (
                  <Circle
                    key={`${carouselSlide}+${i}`}
                    onClick={() => handleClick(i)}
                    $isSelected={i === slide}
                  />
                );
              })}
          </CircleWrapper>
        </InfoSection>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background: ${color.S3100};
`;

const Transition = styled.div<{ $opacity: number; $translateX: string; $transitionSpeed: string }>`
  opacity: ${(p) => p.$opacity};
  translate: ${(p) => p.$translateX} 0;
  transition: ${(p) => p.$transitionSpeed};
`;

const QuoteWrap = styled.div<{ $bigImg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${(p) => (p.$bigImg ? "35rem" : "35rem")};
`;

const Circle = styled.button<{ $isSelected?: boolean }>`
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  padding: 0;
  border: none;
  background-color: ${(p) => (p.$isSelected ? color.white : color.S350)};
  cursor: pointer;
`;

const InfoSection = styled.div<{ $bigImg?: string }>`
  background: ${color.S3100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-bottom: 4.5rem;
  min-height: 10rem;

  ${tablet} {
    padding: 0 1rem 3rem;
    min-height: ${(p) => p.$bigImg && "7rem"};
  }
`;

const CircleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding-top: 2.5rem;

  ${mobile} {
    padding-top: 1rem;
  }
`;
