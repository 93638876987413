import styled, { ThemeContext } from "styled-components";
import { color, fonts } from "../../styles";
import { FC, useState } from "react";
import { mobile, tablet } from "components/templates/Breakpoints";
import { NotMobile } from "components/templates/Breakpoints";
import { Vimeo } from "@services";
import { Link } from "@components/atoms";

export interface QuoteSlideInfoProps {
  avatar?: string;
  bigImg?: string;
  logo?: string;
  quote: string;
  source: string;
  vimeoId?: string;
  videoURL?: string;
  caseStudyURL?: string;
  fontSize?: string;
  opacity?: number;
  translateX?: string;
  transitionSpeed?: string;
}

export const QuoteSlideInfo: FC<QuoteSlideInfoProps> = ({
  avatar,
  bigImg,
  source,
  vimeoId,
  videoURL,
  caseStudyURL,
  opacity,
  translateX,
  transitionSpeed,
}) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  if (bigImg) {
    return null;
  }

  return (
    <Wrapper>
      <VimeoWrapper>
        <Vimeo
          vimeoId={vimeoId}
          isOpen={isVideoOpen}
          onClose={() => {
            setIsVideoOpen(false);
          }}
        />
      </VimeoWrapper>
      <Cont $opacity={opacity} $translateX={translateX} $transitionSpeed={transitionSpeed}>
        <VimeoWrapper>
          <Vimeo
            vimeoId={vimeoId}
            isOpen={isVideoOpen}
            onClose={() => {
              setIsVideoOpen(false);
            }}
          />
        </VimeoWrapper>
        <InfoContainer>
          {avatar && <Avatar alt={source} src={avatar} />}
          <Source>{source}</Source>
          {caseStudyURL && (
            <Link href={caseStudyURL} legacyBehavior>
              <a target="_blank" rel="noopener noreferrer">
                <CarouselButton>Read the case study</CarouselButton>
              </a>
            </Link>
          )}
          {videoURL && (
            <Link href={videoURL} legacyBehavior>
              <CarouselButton>Play the video</CarouselButton>
            </Link>
          )}
          {vimeoId && (
            <ThemeContext.Consumer>
              {(props) => {
                return (
                  <ImageContainer
                    onMouseEnter={() => {
                      props?.preloadVideo(vimeoId);
                    }}
                    onClick={() => {
                      props?.triggerVideo();
                    }}
                  >
                    <CarouselButton>Play the video</CarouselButton>
                  </ImageContainer>
                );
              }}
            </ThemeContext.Consumer>
          )}
        </InfoContainer>
      </Cont>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  min-height: 5rem;
  margin-bottom: 2rem;

  ${tablet} {
    min-height: 110px;
    padding: 0 1rem;
  }
`;

const Cont = styled.div<{ $opacity: number; $translateX: string; $transitionSpeed: string }>`
  display: flex;
  justify-content: center;
  align-items: end;
  opacity: ${(p) => p.$opacity};
  translate: ${(p) => p.$translateX} 0;
  transition: ${(p) => p.$transitionSpeed};
`;

const CarouselButton = styled.button`
  font-size: 1rem;
  color: ${color.S3100};
  font-weight: bold;
  background: ${color.white};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  letter-spacing: 0.075rem;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 4rem;

  ${mobile} {
    display: none;
  }
`;

const Source = styled.p`
  color: ${color.white};
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  text-align: center;
  padding: 0 1rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
`;

export const VimeoWrapper = styled.div`
  position: absolute;
  top: 10.625rem;
  left: 3.4375rem;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
  }

  ${NotMobile} {
    svg {
      transition: 300ms ease-in-out;
    }

    &:hover {
      svg {
        transform: translateY(-4rem) scale(1.2);
      }
    }
  }
`;
